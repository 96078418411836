<template>
  <v-footer padless>
    <v-card-text class="text-center black">
      <v-btn icon class="mx-4 white--text"
        v-for="icon in icons" :key="icon"
      >
        <v-icon size="24px">
          {{ icon }}
        </v-icon>
      </v-btn>
    </v-card-text>

    <v-card flat tile class="black white--text">
      <v-row no-gutters justify="center">
        <v-col cols="6">
          <v-container>
            <v-card-text class="white--text">
              <p id="par">Two things fill the mind with ever-increasing wonder and awe,
                The more often and the more intensely the mind of thought is drawn to them: 
                The starry heavens above me and the moral law within me.
              </p>
              <p id="kant">---- Immanuel Kant</p>
            </v-card-text>
          </v-container>
        </v-col>

        <v-divider vertical></v-divider>

        <v-col cols="3">
          <v-container>
            <div v-for="item of items" :key="item.name">
              <v-list-item :to="item.route">
                <v-list-item-action>
                  <v-icon color="white">
                    {{item.icon}}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="white--text">
                    {{item.name}}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-container>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="3">
          <v-container>
            <!-- <div>联系我们</div> -->
            <v-img width="150" src="https://img.taochin.xyz/images/tutors-photo/QR.jpeg"></v-img>
          </v-container>
        </v-col>
      </v-row>

      <v-card-text class="white--text text-center">
        {{ new Date().getFullYear() }} — <strong>TaoChin International Education Co.</strong>
        <p>
          <a id="beian" href="http://beian.miit.gov.cn/">
            京ICP备2023007328号-1
          </a>
        </p>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
  export default {
    data: () => (
      {
        icons: [
            "mdi-pi-box",
            "mdi-telescope",
            "mdi-flask-empty",
            "mdi-dna",
            "mdi-currency-btc"
        ],
        items: [
          {
            icon: 'mdi-account-plus',
            name: '加入我们', 
            route: '/join-us'
          },{
            icon: 'mdi-home-group-plus',
            name: '商务合作', 
            route: '/bd'
          },
        ]
      }
    )
}
</script>

<style scoped>
p{
  padding-left: 0px;
}
#kant{
  text-align: right;
  padding-right: 10px;
}
#beian{
  color: aliceblue;
  text-decoration: none;
  bottom:0;
  text-align :center;
}
</style>
