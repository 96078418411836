export default {
  state: {
    sys_name: 'IB',
    subjects: [
      {
        name: 'Mathematics',
        icon: 'mdi-matrix'
      },{
        name: 'Physics',
        icon: 'mdi-electron-framework'
      },{
        name: 'Chemistry',
        icon: 'mdi-flask'
      },{ 
        name: 'Biology', 
        icon: 'mdi-leaf' 
      },{ 
        name: 'Computer Science', 
        icon: 'mdi-web' 
      },{ 
        name: 'Economics', 
        icon: 'mdi-piggy-bank-outline' 
      },
    ]
  }
}