<template>
  <v-app>
    <tch-header></tch-header>
    
    <v-main>
      <router-view/>
    </v-main>

    <tch-footer></tch-footer>
  </v-app>
</template>

<script>
import TchHeader from './components/TchHeader.vue'
import TchFooter from './components/TchFooter.vue'

export default {
  components: { TchHeader, TchFooter },
  name: 'App',
  mounted() {
    document.oncontextmenu = new Function("event.returnValue=false;")
    document.onselectstart = new Function("event.returnValue=false;")
  },
};
</script>
