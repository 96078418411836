<template>
  <v-parallax dark :height=screenHeight :src="img_url">
    <!-- align="center" => vertical center -->
    <!-- justify="center" => horizontal center -->
    <v-row align="center" justify="center">
      <v-col class="text-center" cols="6">
        <div id="rect">
          <h1 class="text-h2 font-weight-bold mb-4">
            <span>TaoChin</span>
          </h1>
          <hr>
          <typer-of-cover></typer-of-cover>
        </div>
      </v-col>
    </v-row>
  </v-parallax>
</template>

<script>
import TyperOfCover from '../atoms/TyperOfCover.vue';

export default {
  components: {
    TyperOfCover
  },
  data: () => ({
    screenHeight: 800,
    img_url: 'https://img.taochin.xyz/images/cover/salk.jpeg'
  }),
  created(){
    this.screenHeight = window.innerHeight
  }
}
</script>

<style scoped>
hr{
  border: 0;
  height: 3px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), white, rgba(0, 0, 0, 0));
}
#rect{
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgba(0, 0, 0, 0.2);
}
h1{
  font-family:Georgia, 'Times New Roman', Times, serif;
  mix-blend-mode: exclusion;
}
span{
  font-family: 'Times New Roman', Times, serif;
}
</style>