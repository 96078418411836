<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        persistent
        max-width="800px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            v-bind="attrs"
            v-on="on"
          >
            登录
          </v-btn>
        </template>

        <v-card>
          <v-card-text>
            <v-container>
              <v-row 
                align="center"
                justify="space-around"
              >
                <v-col cols="12">
                  <v-text-field
                    label="手机号码"
                    required
                    v-model="student.phoneNum"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    type="password"
                    label="密码"
                    required
                    v-model="student.password"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
            >
              关闭
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="submit()"
            >
              登录
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-snackbar
      v-model="snackbar"
    >
      {{ resMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
 
<script>
import {login} from '@/api/auth-service'

export default {
  data: () => ({
    dialog: false,
    student: {
      phoneNum: '',
      password: ''
    },
    snackbar: false,
    resMsg: '',
  }),
  methods: {
    async submit(){
      const {data, status} = await login(this.student)

      if(status === 200){
        localStorage.setItem('token', data)
        this.dialog = false
        this.$router.go(0)
      } else {
        this.snackbar = true
        this.resMsg = data
      }
    }
  },
}
</script>