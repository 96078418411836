import api from "./api-config";

export async function sendSms(student){
  return await api.post({
    url: '/register',
    data: student
  })
}

export async function verifySms(student){
  return await api.post({
    url: '/verifyAuthNum',
    data: student
  })
}

export async function login(student){
  return await api({
    method: 'post',
    url: '/login',
    data: student
  })
}

export async function verifyToken(){
  return await api({
    method: 'post',
    url: '/verifyToken',
    headers: {
      authorization: localStorage.getItem('token')
    }
  })
}