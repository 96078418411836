<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        persistent
        max-width="300px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            v-bind="attrs"
            v-on="on"
          >
            退出
          </v-btn>
        </template>

        <v-card>
          <v-card-text>
            <div class="text-h6 pt-10 pl-10" >
              退出当前账号？
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
            >
              关闭
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="submit()"
            >
              确认
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
 
<script>
export default {
  data: () => ({
    dialog: false,
  }),
  methods: {
    submit(){
      localStorage.clear();
      this.$router.go(0)
      this.dialog = false;
    }
  },
}
</script>