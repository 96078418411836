<template>
  <div class="text-center">
    <h1>International Courses</h1>
    <hr>
    <p> Cover Every International Course </p>
    <v-container>
      <v-row 
        wrap
        justify="center"
      >
        <v-col
          v-for="logo of corLogos" :key="logo.name"
          sm="2"
          align-self="center"
        >
          <v-img
            max-height="80"
            max-width="150"
            contain
            :src="logo.link"
          ></v-img>
        </v-col>
    </v-row>
    </v-container>
    <h1></h1>
    <h1>International Contests</h1>
    <hr>
    <p> Comprehensive Resources of International Contests </p>
    <!-- <p>Explore Your Favourite Courses and Contests</p> -->
    <div class="container">
    <v-container>
      <v-row 
        wrap
        justify="center"
      >
        <v-col
          v-for="logo of mathLogos" :key="logo.name"
          sm="2"
          align-self="center"
        >
          <v-img
            max-height="80"
            max-width="150"
            contain
            :src= "`${logo.link}`"
          ></v-img>
        </v-col>
    </v-row>
    </v-container>
    <!-- Physics -->
    <v-container>
      <v-row 
        wrap
        justify="center"
      >
        <v-col
          v-for="logo of phyLogos" :key="logo.name"
          sm="2"
          align-self="center"
        >
          <v-img
            max-height="80"
            max-width="150"
            contain
            :src= "`${logo.link}`"
          ></v-img>
        </v-col>
    </v-row>
    </v-container>
    <!-- Chemistry -->
    <v-container>
      <v-row 
        wrap
        justify="center"
      >
        <v-col
          v-for="logo of chemLogos" :key="logo.name"
          sm="2"
          align-self="center"
        >
          <v-img
            max-height="80"
            max-width="150"
            contain
            :src= "`${logo.link}`"
          ></v-img>
        </v-col>
    </v-row>
    </v-container>
    <!-- Biology -->
    <v-container>
      <v-row 
        wrap
        justify="center"
      >
        <v-col
          v-for="logo of bioLogos" :key="logo.name"
          sm="2"
          align-self="center"
        >
          <v-img
            max-height="80"
            max-width="150"
            contain
            :src= "`${logo.link}`"
          ></v-img>
        </v-col>
    </v-row>
    </v-container>
    <!-- CS -->
    <v-container>
      <v-row 
        wrap
        justify="center"
      >
        <v-col
          v-for="logo of csLogos" :key="logo.name"
          sm="2"
          align-self="center"
        >
          <v-img
            max-height="80"
            max-width="150"
            contain
            :src= "`${logo.link}`"
          ></v-img>
        </v-col>
    </v-row>
    </v-container>
    <!-- Eco & Biz -->
    <v-container>
      <v-row 
        wrap
        justify="center"
      >
        <v-col
          v-for="logo of ecoBizLogos" :key="logo.name"
          sm="2"
          align-self="center"
        >
          <v-img
            max-height="80"
            max-width="150"
            contain
            :src= "`${logo.link}`"
          ></v-img>
        </v-col>
    </v-row>
    </v-container>
    </div>
  </div>
</template>

<script>
const imgUrl = 'https://img.taochin.xyz/images/logos/';

export default {
  data() {
    return {
      corLogos: [
        { 
          name: 'AP',
          link: imgUrl + 'AP.png'
        },
        { 
          name: 'IB',
          link: imgUrl + 'IB.png' 
        },
        { 
          name: 'CIE',
          link: imgUrl + 'CIE.png'
        },
        { 
          name: 'Edexcel',
          link: imgUrl + 'Edexcel.png' 
        },
        { 
          name: 'AQA',
          link: imgUrl + 'AQA.png'
        },
        { 
          name: 'OCR',
          link: imgUrl + 'OCR.png' 
        },
        { 
          name: 'WJEC',
          link: imgUrl + 'WJEC.png' 
        },
        { 
          name: 'Eduqas',
          link: imgUrl + 'Eduqas.png' 
        },
        { 
          name: 'BC',
          link: imgUrl + 'BC.png'
        },
        { 
          name: 'VCE',
          link: imgUrl + 'VCE.svg' 
        },
      ],
      mathLogos: [
        { 
          name: 'MAA',
          link: imgUrl + 'MAA.png',
        },
        { 
          name: 'AMC',
          link: imgUrl + 'AMC.png',
        },
        { 
          name: 'COMC',
          link: imgUrl + 'COMC.png'
        },
        { 
          name: 'Euclid',
          link: imgUrl + 'Euclid.png',
        },
        { 
          name: 'Purple Comet',
          link: imgUrl + 'Purple%20Comet.png',
        },
        { 
          name: 'AMRL',
          link: imgUrl + 'ARML.png',
        },
        { 
          name: 'BMT',
          link: imgUrl + 'BMT.png',
        },
        { 
          name: 'BAMO',
          link: imgUrl + 'BAMO.png'
        },
        { 
          name: 'PUMaC',
          link: imgUrl + 'PUMaC.png',
        },
        { 
          name: 'DMM',
          link: imgUrl + 'DMM.png',
        },
        { 
          name: 'HMMT',
          link: imgUrl + 'HMMT.png',
        },
        { 
          name: 'SMT',
          link: imgUrl + 'SMT.png',
        },
        { 
          name: 'JHMT',
          link: imgUrl + 'JHMT.png',
        },
        { 
          name: 'CHMMC',
          link: imgUrl + 'CHMMC.png',
        },
        { 
          name: 'Ross',
          link: imgUrl + 'Ross.svg'
        },
        { 
          name: 'Promys',
          link: imgUrl + 'Promys.png'
        },
        { 
          name: 'SUMaC',
          link: imgUrl + 'SUMaC.svg'
        },
        { 
          name: 'HCSSiM',
          link: imgUrl + 'HCSSiM.png'
        },
        { 
          name: 'UKMT',
          link: imgUrl + 'UKMT.png',
        },
        { 
          name: 'AMT',
          link: imgUrl + 'AMT.webp'
        },
        { 
          name: 'MPFG',
          link: imgUrl + 'MPFG.png',
        },
      ],
      phyLogos: [
        { 
          name: 'PhysicsBowl',
          link: imgUrl + 'PhysicsBowl.png',
        },
        { 
          name: 'BPhO',
          link: imgUrl + 'BPhO.png',
        },
        { 
          name: 'BAAO',
          link: imgUrl + 'BAAO.png',
        },
        { 
          name: 'PUPC',
          link: imgUrl + 'PUPC.png',
        },
        { 
          name: 'CAP',
          link: imgUrl + 'CAP.png'
        },
        { 
          name: 'Phy-photo',
          link: imgUrl + 'Phy-photo.png'
        },
        { 
          name: 'SIN',
          link: imgUrl + 'SIN.png'
        },
      ],
      chemLogos: [
        { 
          name: 'ASOP',
          link: imgUrl + 'ASOP.png'
        },
        { 
          name: 'USNCO',
          link: imgUrl + 'USNCO.svg'
        },
        { 
          name: 'UKChO',
          link: imgUrl + 'UKChO.png'
        },
        { 
          name: 'CCC/CCO',
          link: imgUrl + 'CCC_CCO.png'
        },
      ],
      bioLogos: [
        { 
          name: 'USABO',
          link: imgUrl + 'USABO.png'
        },
        { 
          name: 'BBO',
          link: imgUrl + 'BBO.png'
        },
        { 
          name: 'BrainBee',
          link: imgUrl + 'BrainBee.png'
        }
      ],
      csLogos: [
        { 
          name: 'USACO',
          link: imgUrl + 'USACO.jpeg',
        },
        { 
          name: 'CMIMC',
          link: imgUrl + 'CMIMC.png',
        },
        { 
          name: 'ACSL',
          link: imgUrl + 'ACSL.png',
        },
        { 
          name: 'CAT',
          link: imgUrl + 'CAT.png',
        },
        { 
          name: 'OUCC',
          link: imgUrl + 'OUCC.png',
        },
      ],
      ecoBizLogos: [
        { 
          name: 'NEC',
          link: imgUrl + 'NEC.png'
        },
        { 
          name: 'IEO',
          link: imgUrl + 'IEO.png'
        },
        { 
          name: 'EEO',
          link: imgUrl + 'EEO.png'
        },
        { 
          name: 'DECA',
          link: imgUrl + 'DECA.png'
        },
        { 
          name: 'FBLA',
          link: imgUrl + 'FBLA.png'
        },
        { 
          name: 'MITMUNC',
          link: imgUrl + 'MITMUNC.png'
        },
      ],

      conLogos: [
        this.mathLogos,
        this.phyLogos, 
        this.chemLogos,
        this.bioLogos,
        this.csLogos,
        this.ecoBizLogos
      ]
        // { 
        //   name: 'GEO',
        //   link: 'http://globaleconomicsolympiad.com/img/logo/global-Economics-logo.png'
        // },
        // { 
        //   name: 'ASOC',
        //   link: 'https://oss.linstitute.net/wechatimg/2019/08/asoclogo.png'
        // }
        // { 
        //   name: 'ASI',
        //   link: 'https://scienceandtechnologyaustralia.org.au/wp-content/uploads/2018/12/ASI-logo-800x450.jpg'
        // },
    }
  }
}
</script>

<style scoped>
h1{
  margin-top: 50px;
  margin-bottom: 5px;
}
p{
  margin-top: 10px;
}
hr{
  border: 0;
  height: 3px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(9, 84, 132), rgba(0, 0, 0, 0));
}
</style>