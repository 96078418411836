<template>
  <div class="text-center">
    <v-menu offset-y dense open-on-hover>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on" text>
          国际课程
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item 
            v-for="(item, index) in items" 
            :key="index" 
            :to=" '/paper-bank' + item.link "
          >
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
  export default {
    data: () => ({
      selectedItem: 1,
      items: [
        { 
          title: 'AP',
          link: '/AP'
        },{ 
          title: 'IB',
          link: '/IB'
        },{ 
          title: 'A-Level (CIE)',
          link: '/AL-CIE'
        },{ 
          title: 'IGCSE (CIE)',
          link: '/IG-CIE'
        },{ 
          title: 'A-Level (Edexcel)',
          link: '/AL-Edexcel',
        },{ 
          title: 'IGCSE (Edexcel)',
          link: '/IG-Edexcel',
        },
      ],
    }),
  }
</script>