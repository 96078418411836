export default {
  state: {
    sys_name: 'AP',
    subjects: [
      { 
        name: 'Calculus', 
        icon: 'mdi-math-integral-box' 
      },{ 
        name: 'Statistics', 
        icon: 'mdi-chart-bell-curve' 
      },{ 
        name: 'Computer Science A', 
        icon: 'mdi-language-java' 
      },{ 
        name: 'Computer Science Principle', 
        icon: 'mdi-laptop' 
      },{ 
        name: 'Physics',
        icon: 'mdi-magnet-on'
      },{ 
        name: 'Chemistry', 
        icon: 'mdi-flask' 
      },{ 
        name: 'Biology', 
        icon: 'mdi-leaf' 
      },{ 
        name: 'Microeconomics', 
        icon: 'mdi-piggy-bank-outline' 
      },{ 
        name: 'Macroeconomics', 
        icon: 'mdi-bank' 
      },
    ]
  }
}