<template>
  <v-app-bar app>
    <v-toolbar-title>
      <a href="/">
        <span id="tao">Tao</span>
        <span id="chin">Chin</span>
      </a>
    </v-toolbar-title>
  
    <v-spacer></v-spacer>

    <v-btn text>
      <router-link 
        to="/"
        style="text-decoration: none"
      >
        主页
      </router-link>
    </v-btn> 
  
    <menu-course></menu-course>
    <menu-contest></menu-contest>

    <v-btn v-for="btn in btns" :key="btn.link" text>
      <router-link 
        :to="btn.link"
        style="text-decoration: none"
      >
        {{ btn.page }}
      </router-link>
    </v-btn> 

    <menu-lect></menu-lect>

    <v-btn text>
      <router-link 
        to="/search"
        style="text-decoration: none"
      >
        题目搜索
      </router-link>
    </v-btn>

    <v-spacer></v-spacer>
    
    <span v-if='isLogin'>
      <btn-logout></btn-logout>
    </span>
    <span v-else>
      <v-row>
        <v-col cols="6">
          <btn-register></btn-register>
        </v-col>
        <v-col cols="6">
          <btn-login></btn-login>
        </v-col>
      </v-row>
    </span>
  </v-app-bar>
</template>

<script>
import { verifyToken } from '@/api/auth-service';

import MenuCourse from './atoms/MenuCourse.vue';
import MenuContest from './atoms/MenuContest.vue';
import BtnRegister from './atoms/BtnRegister.vue';
import BtnLogin from './atoms/BtnLogin.vue'
import BtnLogout from './atoms/BtnLogout.vue';
import MenuLect from './atoms/MenuLect.vue';

export default{
  name: "MyHeader",
  components: {
    MenuCourse,
    MenuContest,
    BtnRegister,
    BtnLogin,
    BtnLogout,
    MenuLect
  },
  data() {
    return {
      isLogin: false,
      btns: [
        { 
          page: "牛剑入学考",
          link: "/paper-bank/Uni-Admissions" 
        },{
          page: "分类题库",
          link: "/topic-bank"
        // },{
        //   page: "道勤课堂",
        //   link: "/lect-video"
        }
      ],
    };
  },
  async created(){
    let token = localStorage.getItem('token');
    
    if(token){
      try {
        const {data} = await verifyToken()
      
        if(data == null){
          localStorage.clear()
        } else {
          this.isLogin = true
        } 
      } catch (err) {
        localStorage.clear()
        console.log(err.code)
      }
    }
  }
}
</script>

<style scoped>
.router-link-active{
  text-decoration: none;
}
#tao{
  margin-left: 3em;
  font-size: larger;
  font-weight: bold;
  color: black;
}
#chin{
  font-size: larger;
  font-weight: bold;
  color: red;
}
a{
  text-decoration: none;
}
</style>