<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="800px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on">
            注册
          </v-btn>
        </template>

        <v-card>
          <v-card-text>
            <v-container>
              <v-row align="center" justify="space-around">
                <v-col cols="12">
                  <v-text-field
                    label="用户名"
                    required
                    :rules="nameRules"
                    v-model="student.name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    type="password"
                    label="密码"
                    required
                    :rules="passwordRules"
                    v-model="student.password"
                  ></v-text-field>
                </v-col>

                <v-col cols="8">
                  <v-text-field
                    label="学校"
                    required
                    :rules="schoolRules"
                    v-model="student.school"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-overflow-btn
                    class="my-2"
                    :items="sysItems"
                    label="课程体系"
                    v-model="student.examBoards"
                    dense
                  ></v-overflow-btn>
                </v-col>
                
                <v-col cols="12">
                  <v-text-field
                    label="手机号码"
                    required
                    :rules="phoneNumRules"
                    v-model="student.phoneNum"
                  ></v-text-field>
                </v-col>

                <div v-if="countdown === 0">
                  <v-col cols="3">
                    <v-btn 
                      text
                      color="indigo"
                      @click="sendSms()"
                      :disabled="disableSmsBtn"
                    >
                      发送验证码
                    </v-btn>
                  </v-col>
                </div>
                <div v-else>
                  <div class="ma-auto" style="max-width: 300px">
                    <v-row>
                      <v-col cols="9">
                        <v-otp-input
                          v-model="student.authData"
                          length="4"
                          plain
                        ></v-otp-input>
                      </v-col>
                      <v-col 
                        cols="3"
                        class="my-3"
                      >
                        <v-btn 
                          block
                          color="success"
                          @click="verifySms()"
                        >
                          提交
                        </v-btn>
                      </v-col>
                    </v-row>
                    
                    <pre>{{ countdown }} 秒</pre>
                    
                  </div>
                </div>
              </v-row>
            </v-container>
          </v-card-text>

          <div>
            
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
            >
              关闭
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-snackbar
      v-model="snackbar"
    >
      {{ resMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
 
<script>
import api from '@/api/api-config';

export default {
  data: () => ({
    nameRules: [
      v => !!v || 'Name is required',
      v => v.length <= 20 || 'Name must be less than 20 characters',
      v => v.length >= 2 || 'Name must be greater than 2 characters',
    ],
    passwordRules: [
      v => !!v || 'Password is required',
      v => v.length >= 6 || 'Password must be greater than 6 characters',
    ],
    schoolRules: [
      v => !!v || 'School is required',
    ],
    phoneNumRules: [
      v => !!v || 'Phone number is required',
      v => /^1[3|4|5|6|7|8|9][0-9]{9}$/.test(v) || 'Phone number is not correct'
    ],
    student: {
      name: '',
      password: '',
      school: '',
      examBoards: '',
      phoneNum: '',
      authData: ''
    },
    sysItems: [
      'AP',
      'IB',
      'AL-CIE',
      'IG-CIE',
      'AL-Edexcel',
      'IG-Edexcel',
      'AQA'
    ],
    snackbar: false,
    resMsg: "",
    dialog: false,
    countdown: 0, // 初始倒计时时间
    timer: null, // 计时器,
    // otp: ''
  }),
  computed: {
    disableSmsBtn(){
      return this.student.name.length === 1
        || this.student.name.length > 21
        || this.student.password.length < 6
        || this.student.school.length === 0
        || this.student.phoneNum.length !== 11;
    }
  },
  methods: {
    verifySms(){
      api({
        method: 'post',
        url: '/verifyAuthNum',
        data: this.student
      }).then(res => {
        if(res.status === 201){
          this.dialog = false;
        } else {
          this.snackbar = true;
          this.resMsg = res.data;
        }
      }).catch(err => {
        console.log(err)
      })
    },
    sendSms(){
      api({
        method: 'post',
        url: '/register',
        data: this.student
      }).then(res => {
        if(res.status === 201){
          this.countdown = 120;
          this.startCountdown();  
        } else {
          this.snackbar = true;
          this.resMsg = res.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    startCountdown() {
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(this.timer); // 倒计时结束后清除计时器
        }
      }, 1000);
    }
  },
}
</script>