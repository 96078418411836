export default {
  state: {
    sys_name: 'Contest',
    subjects: [
      {
        name: 'AMC 8',
        icon: 'mdi-function-variant'
      },{
        name: 'AMC 10 - 12',
        icon: 'mdi-function-variant'
      },{
        name: 'AIME',
        icon: 'mdi-function-variant'
      },{ 
        name: 'PhysicsBowl', 
        icon: 'mdi-telescope' 
      },{ 
        name: 'BPhO', 
        icon: 'mdi-telescope' 
      },{ 
        name: 'CAP', 
        icon: 'mdi-telescope' 
      },{ 
        name: 'F = ma', 
        icon: 'mdi-telescope' 
      },{ 
        name: 'USNCO', 
        icon: 'mdi-flask-outline' 
      },{ 
        name: 'UKChO', 
        icon: 'mdi-flask-outline' 
      },{ 
        name: 'USABO', 
        icon: 'mdi-microscope' 
      },
    ]
  }
}