var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"typer-of-cover"},[_c('vue-typer',{staticClass:"vue-typer",attrs:{"repeat":Infinity,"shuffle":true,"erase-style":"backspace","pre-erase-delay":2000,"type-delay":150,"erase-delay":25,"caret-animation":"expand","text":[
      "Welcome to Your Future! ",
      "Welcome to TaoChin! ",
      "Mathematics ",
      "Physics ",
      "Chemistry ",
      "Biology ",
      "Computer Science ",
      "Economics ",
      "Business ",
      "Literature ",
      "History "
    ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }