import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import topic_AP from './modules/topic-ap'
import topic_IB from './modules/topic-ib'
import topic_AL_CIE from './modules/topic-al-cie'
import topic_IG_CIE from './modules/topic-ig-cie'
import topic_Contest from './modules/topic-contest'

export default new Vuex.Store({
  state: { 
    isLogin: false
  },
  getters: { },
  actions: { },
  mutations: {
    setLogin(state){
      state.isLogin = true
    },
    setLogout(state){
      state.isLogin = false
    }
  },
  modules: {
    topic_AP,
    topic_IB,
    topic_AL_CIE,
    topic_IG_CIE,
    topic_Contest,
  }
})
