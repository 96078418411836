<template>
  <div>
    <section-of-cover></section-of-cover>
    <section-of-logos></section-of-logos>
  </div>
</template>

<script>
import SectionOfCover from '@/components/home/SectionOfCover.vue'
import SectionOfLogos from '@/components/home/SectionOfLogos.vue'

export default {
  name: 'HomeView',

  components: {
    SectionOfCover,
    SectionOfLogos,
  },
}
</script>
