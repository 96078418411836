import { render, staticRenderFns } from "./TchHeader.vue?vue&type=template&id=3edd4b56&scoped=true"
import script from "./TchHeader.vue?vue&type=script&lang=js"
export * from "./TchHeader.vue?vue&type=script&lang=js"
import style0 from "./TchHeader.vue?vue&type=style&index=0&id=3edd4b56&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3edd4b56",
  null
  
)

export default component.exports