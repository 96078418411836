<template>
  <div class="text-center" >
    <v-menu offset-y dense open-on-hover>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on" text>
          精选讲义
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item-group>
          <v-list-item 
            v-for="(item, index) in items" 
            :key="index" 
            :to=" '/lect-bank' + item.link "
          ><v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  data: () => ({
    items: [
      { 
        title: '数学/计算机',
        link: '/Math-and-CS'
      },{ 
        title: '自然科学类',
        link: '/Natural-Science'
      },{ 
        title: '人文社科类',
        link: '/Social-Science'
      },
    ],
  }),
}
</script>
